import { useRouter } from "next/router";
import {
  getExpires,
  getI18nCookie,
  hasI18nCookie,
  I18N_COOKIE_KEY,
  setI18nCookie,
} from "../models/cookie";
import i18n from "../models/i18n";

export function getI18n(locale) {
  i18n.setLocale(locale);

  return i18n;
}

export function useI18n(pathname) {
  if (!pathname) {
    ({ pathname } = useRouter());
  }
  const locale = pathname.includes("/en") ? "en" : "de";

  return getI18n(locale);
}

export function withI18n(Component) {
  return (props) => {
    const i18n = useI18n();

    return <Component {...props} i18n={i18n} />;
  };
}

function i18nRedirectToEn(res, redirectTo) {
  const secure = process.env.NODE_ENV === "production" ? "Secure" : "";

  res
    .writeHead(302, {
      Location: redirectTo,
      "set-cookie": [
        `${I18N_COOKIE_KEY}=en; Path=/; Expires=${getExpires().toUTCString()}; ${secure}`,
      ],
    })
    .end();
}

export function withI18nRedirect(req, res, { redirectToEn, redirectTo } = {}) {
  redirectToEn = redirectToEn || i18nRedirectToEn;
  redirectTo = redirectTo || "/en";

  if (hasI18nCookie(req)) {
    if (getI18nCookie(req) === "en") {
      redirectToEn(res, redirectTo);
    }

    return;
  }

  const acceptLanguage = req.headers["accept-language"] || "de";

  if (acceptLanguage.startsWith("de")) {
    setI18nCookie(res, "de");
    return;
  }

  redirectToEn(res, redirectTo);
}
