import fetch from "isomorphic-unfetch";
import endpointConfig from "../config/endpoints";
import isoCookie from "isomorphic-cookie";
import { DEFAULT_IMAGES } from "../components/WishlistImagePicker";
import { getExpires } from "./cookie";
import { sanitizeWish } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/wish";
import { sanitizeWishlist } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/wishlist";

export const DEFAULT_DESCRIPTION =
  "Das ist dein wunderbarer Wunschzettel. Denk dir doch einen eigenen Namen aus und ändere die Beschreibung, damit jeder weiß, für welchen Anlass du dir die zauberhaften Dinge wünschst.";
export const DEFAULT_IMAGE = DEFAULT_IMAGES[0];

export async function getWishlists(cookie) {
  const config = endpointConfig();
  const options = cookie
    ? {
        headers: {
          Cookie: cookie,
        },
      }
    : {};

  return await fetch(config.wishlists, {
    credentials: "include",
    ...options,
  }).then(
    (response) =>
      response.ok &&
      response.json().then((lists) => lists.map(sanitizeWishlist)),
    () => null
  );
}

export async function getWishlist(uuid, cookie) {
  return fetch(endpointConfig().wishlist(uuid), {
    credentials: "include",
    headers: {
      Cookie: cookie,
    },
  }).then(
    (res) => res.ok && res.json().then(sanitizeWishlist),
    () => null
  );
}

export async function getSharedWishlist(publicUuid) {
  return fetch(
    `${endpointConfig().wishlist(publicUuid)}?identifierField=publicUuid`,
    {
      credentials: "include",
    }
  ).then(
    (res) => res.ok && res.json().then(sanitizeWishlist),
    () => null
  );
}

export function updateWishlist(uuid, data) {
  const config = endpointConfig();

  return fetch(config.wishlist(uuid), {
    method: "PATCH",
    body: JSON.stringify(data),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.ok && response.json().then(sanitizeWishlist));
}

export function deleteWishlist(uuid) {
  const config = endpointConfig();

  return fetch(config.wishlist(uuid), {
    method: "DELETE",
    credentials: "include",
  });
}

export function claimWishlist(uuid) {
  const config = endpointConfig();

  return fetch(config.wishlistClaim(uuid), {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => response.ok && response.json().then(sanitizeWishlist));
}

export function inviteCollaborator(uuid, email) {
  const config = endpointConfig();

  return fetch(config.inviteCollaborator(uuid), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Ooops");
    }

    return res;
  });
}

export function removeCollaborator(uuid, email) {
  const config = endpointConfig();

  return fetch(config.inviteCollaborator(uuid), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Ooops");
    }

    return res;
  });
}

export function getCollaborators(uuid) {
  const config = endpointConfig();

  return fetch(config.collaborators(uuid), {
    method: "GET",
    credentials: "include",
  }).then((res) => {
    if (!res.ok) {
      throw new Error("Ooops");
    }

    return res.json();
  });
}

/********************/
/**** Cookies *******/
/********************/

export const cookieKey = "wishlists";

export function readFromCookies(req, { enrich = false } = {}) {
  const config = endpointConfig();
  const wishlists = isoCookie.load(cookieKey, req) || [];

  if (!enrich) {
    return wishlists.map(sanitizeWishlist);
  }

  const uuids = wishlists.map((wishlist) => wishlist.uuid);

  if (!uuids || uuids.length === 0) {
    return [];
  }

  return fetch(`${config.wishlists}?uuid=${uuids.join(",")}`, {})
    .then(
      (response) => response.ok && response.json(),
      () => []
    )
    .then((wishlistsFromApi) => {
      const wishlistUuidsFromApi = wishlistsFromApi.map(
        (wishlist) => wishlist.uuid
      );
      uuids.forEach((uuidInCookie) => {
        if (!wishlistUuidsFromApi.includes(uuidInCookie)) {
          removeFromCookies({ uuid: uuidInCookie });
        }
      });
      return wishlistsFromApi.map(sanitizeWishlist);
    });
}

export function addToCookies(newWishlist) {
  const storedWishlists = readFromCookies();
  const updatedWishlists = storedWishlists.concat(newWishlist);

  return isoCookie.save(cookieKey, updatedWishlists, {
    // fixme move to central place
    secure: process.env.NODE_ENV === "production",
    expires: getExpires(),
  });
}

export function removeFromCookies(wishlist) {
  const storedWishlists = readFromCookies();
  const updatedWishlists = storedWishlists.filter(
    (storedWishlist) => storedWishlist.uuid !== wishlist.uuid
  );

  return isoCookie.save(cookieKey, updatedWishlists, {
    secure: process.env.NODE_ENV === "production",

    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
  });
}

/********************/
/**** Helpers *******/
/********************/

export function canInvitePeople(user, wishlist) {
  return (
    !wishlist.isCollaboration && (wishlist.owner || {}).email === user.email
  );
}
