import isoCookie from "isomorphic-cookie";
import { COOKIE_NAME, COOKIE_NAME_BLOG } from "../config/gdpr";
import base64 from "base-64";

export function hasAgreedToCookieUsageType(type) {
  const cookie = getCookieNoticeAcceptanceState();

  return (cookie || []).includes(type.name);
}

export function getCookieNoticeAcceptanceState() {
  clearLegacyCookie();

  const cookie = isoCookie.load(COOKIE_NAME);

  if (cookie) {
    return JSON.parse(base64.decode(cookie));
  }
}

export function clearLegacyCookie() {
  isoCookie.remove(COOKIE_NAME_BLOG, { path: "/" });
}
