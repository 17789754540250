import fetch from "isomorphic-unfetch";
import endpointConfig from "../config/endpoints";

import { NO_CACHE_HEADERS } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/auth";

export async function getUserInfo(cookie) {
  const config = endpointConfig();

  return await fetch(config.userInfo, {
    credentials: "include",
    headers: {
      Cookie: cookie,
    },
  }).then(
    (response) => response.ok && response.json(),
    () => null
  );
}

export async function getUserStats(cookie) {
  const config = endpointConfig();

  return await fetch(config.userStatistics, {
    credentials: "include",
    headers: {
      Cookie: cookie,
      ...NO_CACHE_HEADERS,
    },
  }).then(
    (response) => response.ok && response.json(),
    () => null
  );
}

// Used on browser-side only
export function startAccountDeletion() {
  const config = endpointConfig();

  return fetch(config.userInfo, {
    method: "DELETE",
    credentials: "include",
    headers: {
      ...NO_CACHE_HEADERS,
    },
  });
}

export async function createUser(data) {
  const config = endpointConfig();

  return fetch(config.userCreate, {
    method: "POST",
    body: JSON.stringify(data),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
}

export async function activateUser(cookie, username, token, wishlists) {
  const config = endpointConfig();

  return await fetch(config.userActivate, {
    method: "POST",
    body: JSON.stringify({
      username,
      token,
      wishlists: wishlists.map((w) => w.uuid),
    }),
    credentials: "include",
    headers: {
      Cookie: cookie,
      "Content-Type": "application/json",
    },
  });
}

export async function resetPassword({ email }) {
  const config = endpointConfig();

  return fetch(config.passwordReset, {
    method: "POST",
    body: JSON.stringify({ email }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function updatePassword({ email, password, token }) {
  const config = endpointConfig();

  return fetch(config.passwordUpdate, {
    method: "PUT",
    body: JSON.stringify({
      email,
      token,
      password,
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function deleteUser({ deletionToken }) {
  const config = endpointConfig();

  return fetch(`${config.userCreate}/${deletionToken}`, {
    method: "DELETE",
  });
}
