import { faGlobe, faGlobeAmericas, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { redirectTo } from "../../helpers/redirect";
import { useI18n } from "../../hooks/use-i18n";
import { setI18nCookie } from "../../models/cookie";
import "./style.less";

export function LanguagePicker() {
  const i18n = useI18n();

  function handleLanguageSelection(url, language) {
    return (e) => {
      e.preventDefault();
      setI18nCookie(null, language);
      redirectTo(null, url, 302);
    };
  }

  return (
    <div className="language-selector">
      <FontAwesomeIcon icon={faGlobeAmericas} className="icon" />

      <a href="/" onClick={handleLanguageSelection("/", "de")}>
        {i18n.t("languages.german")}
      </a>

      <a>|</a>
      
      <a href="/en" onClick={handleLanguageSelection("/en", "en")}>
        {i18n.t("languages.english")}
      </a>
    </div>
  );
}
