import de from "@bitte-kaufen/bitte-kaufen-common/dist/src/i18n/de.json";

export default {
  ...de,
  languages: {
    german: "Deutsch",
    english: "Englisch",
  },
  web: {
    common: {
      createWishlist: "Wunschzettel anlegen",
      createAccount: "Konto anlegen",
      toWishlist: "Zum Wunschzettel",
      success: "Erfolg!",
    },
    urls: {
      root: "/",
      wishlistsIndex: "/wunschzettel",
      wishlistsShow: "/wunschzettel/%{uuid}",
      wishlistsShowPublic: "/wunschzettel/teilen/%{uuid}",
      friendsIndex: "/wunschzettel#friends",
      giftfinderIndex: "/geschenkefinder",
      giftfinderShow: "/geschenkefinder/%{uuid}",
      accountIndex: "/konto",
      accountLogin: "/konto/einloggen",
      accountLogout: "/konto/ausloggen",
      accountRegister: "/konto/anlegen",
      passwordChange: "/konto/passwort-aendern",
      passwordForgotten: "/konto/passwort-vergessen",
    },
    login: {
      teaser:
        "Melde dich an, um auf deine gespeicherten Wunschzettel zuzugreifen. In deinem Konto speicherst du Wünsche und verschickst deine Wunschliste an Freunde und Familie.",
    },
    register: {
      teaser1:
        "Es gibt nur Vorteile, wenn du deine eigenen bitte.kaufen Wunschzettel anlegst. Dann kannst du alle deine Wunschlisten in deinem Konto einsehen. bitte.kaufen bleibt für dich kostenlos - für immer.",
      teaser2:
        "Deine Daten speichern wir nur, damit du dich einloggen kannst. Ansonsten lieben wir Datensparsamkeit und tolle Geschenkideen. Was wünschst du dir? :)",
    },
    header: {
      ourApp: "Unsere App",
      account: "Konto",
    },
    landingPage: {
      featuredArea: {
        title: "Wunschzettel kostenlos erstellen, mit Freude teilen und beschenken lassen.",
      },
      benefits: {
        free: "Kostenlos",
        withoutRegistration: "Ohne Anmeldung",
        easyToUse: "Intuitiv & einfach",
        ourApp: "Unsere App",
      },
      explanations: {
        nativeApp: {
          title: "Unsere Wunschzettel-App",
          body: [
            `Yay, unsere bitte.kaufen Wunschzettel-App ist da! Ab sofort kannst du diese kostenlos herunterladen!
              Darin speicherst du deine Wunschlisten und die deiner Freunde und Familie. Außerdem lädst du damit andere zur Mitarbeit ein.`,
          ],
        },
        free: {
          title: "Kostenlos Wunschzettel erstellen",
          body: [
            `bitte.kaufen ist und bleibt gratis Für dich und alle, 
            mit denen du deine Wünsche teilst. Wir schenken dir sogar noch etwas - 
            neben unserem Online-Wunschzettel: 
            Viele Geschenkideen für Kinder und Erwachsene. 
            Falls du mal nicht weißt, was du dir wünschen sollst. ;)`,
          ],
        },
        withoutRegistration: {
          title: "Ohne Anmeldung",
          body: [
            `Deinen Wunschzettel kannst du einfach ohne Anmeldung 
            füllen und versenden. Wir empfehlen aber, ein kostenloses Konto anzulegen, 
            damit du nicht alle Links zu deinen Wunschzetteln als Lesezeichen speichern musst. 
            Wir behandeln deine Daten vertraulich.`,
          ],
        },
        forEveryone: {
          title: "Für jeden geeignet",
          body: [
            `Das kann doch jedes Kind - und Oma und Opa! 
            Mit dem Konzept haben wir bitte.kaufen erstellt. Einfach Wunschzettel anlegen,
            Wünsche hinzufügen und über Geschenke freuen. Die Wunschlisten sind also für alle gedacht, 
            die online unterwegs sind, aber manchmal offline shoppen wollen.`,
          ],
        },
        easyToUse: {
          title: "Intuitiv & einfach benutzbar",
          body: [
            `Unser Ziel: Der Online-Wunschzettel soll einfach bedienbar sein. 
            Selbst Kinder und Großeltern sollen sich einfach einen Wunschzettel 
            anlegen und Geschenke für andere kaufen können. Keine verzweifelten Anrufe, 
            weil alles kompliziert ist!`,
          ],
        },
        summary: {
          title: "Kostenlos Wunschzettel erstellen",
          body: [
            `bitte.kaufen - Dein Wunschzettel ist kostenlos - für immer.
            Wunschzettel füllen soll glücklich machen und die Vorfreude auf das
            Beschenktwerden steigern. Daher versprechen wir dir: Bezahlen musst du
            unseren Service nicht, nicht mal mit Daten.`,
            `Deine Daten werden anonym gespeichert. Deine Wunschzettel sind von außen nicht auffindbar, es
            sei denn, du teilst sie mit Freunden und Familie. Unser
            Online-Wunschzettel bitte.kaufen funktioniert auch ohne Registrierung.
            Du behältst deine Daten und somit deine Geheimnisse für dich. Du
            verrätst nur, was du dir von deinen Liebsten wünschst. Wir drücken dir
            die Daumen, dass diese Wünsche wahr werden. :)`,
          ],
        },
        forGrandParents: {
          title: "Einfach verständlich für Oma & Opa",
          body: [
            `Du hast genug von Shop-abhängigen Wunschlisten weil Oma und Opa
            Schwierigkeiten beim Wünsche erfüllen haben? Das kennen wir. Mit
            bitte.kaufen erstellst du deinen kostenlosen Wunschzettel, auf den du
            alle Produkte deiner Lieblings-Shops schreiben kannst.`,
            `Oma und Opa suchen das, was sie am einfachsten kaufen können - z. B. im Laden
            um die Ecke. Mit einem Klick reservieren sie den Wunsch, den sie dir
            schenken möchten und sind somit unabgängig vom Online-Shopping.`,
          ],
        },
        forEveryOccasion: {
          title: "Wunschzettel für jeden Anlass",
          body: [
            `Die ersten Ideen geben wir dir vor: Erstelle doch eine Wunschliste zum
            Geburtstag für dein Baby, deine Kinder, Weihnachten, Ostern, zur
            Einschulung oder für deine Hochzeit! Deiner Fantasie sind keine
            Grenzen gesetzt. Und weil es einfach sein soll: Einfach Wunschzettel
            erstellen, easy mit Freunden teilen, ein Geschenk reservieren, kannst
            du so viele Wunschzettel erstellen, wie du möchtest.`,
            `Du planst einen geheimen Junggesellen-Abschied, Omas 85. Geburtstag oder organisierst
            für deine Freundin eine Babyparty? Du schmeißt eine Grillparty und
            jeder soll etwas mitbringen? Du veranstaltest einen Zock-Abend oder
            Brunch mit deinen Liebsten? Egal, wofür du unseren Online-Wunschzettel
            benutzt: bitte.kaufen ist einfach zu bedienen und für jeden Anlass
            geeignet.`,
            `Was liegt an: Geburtstag, Hochzeit, Einschulung, Weihnachten, Ostern oder Brunch? Mit bitte.kaufen bastelst du dir deinen eigenen Wunschzettel für jeden Anlass zusammen. Wie wäre es mit einer Wunschliste für die Goldene Hochzeit deiner Großeltern, die du organisierst? Oder du fängst mit deinen geheimsten Wünschen an.`,
          ],
        },
      },
    },
    wishlistsIndex: {
      hero: {
        title: "Deine Wunschzettel",
        text: `Hier siehst du alle bereits angelegten Wunschzettel. Zudem
        kannst mit ein paar Klicks eine neue Wunschliste anlegen.
        Vielleicht willst du auch geheime Geheimnisse abspeichern, nur
        für dich?`,
      },
      separators: {
        yourWishlists: "Deine Wunschzettel",
        friendsWishlists: "Wunschzettel von Freunde",
      },
    },
    wishlistsCreate: {
      title: "Neuer Wunschzettel",
      titlePlaceholder: "Name des Wunschzettels",
      submitButton: "Wunschzettel speichern",
    },
    wishlistsShow: {
      newWish: {
        title: "Neuer Wunsch",
        cta: "Wunsch hinzufügen",
      },
      manage: {
        title: "Wunschzettel verwalten",
        invite: "Einladen",
        move: "Bewegen",
      },
      edit: {
        confirmWishDeletion: "Wunsch wirklich löschen!",
        title: "Titel",
        hideBoughtWishesExplanation: `
          Durch diese Option werden gekaufte Wünsche vor dir versteckt, sodass
          dich Freunde und Familie besser überraschen können!
        `,
        image: "Bild",
        scrollOnForMore: "Scrollen für mehr!",
      },
      share: {
        close: "Teilen beenden",
        forFriendsAndFamily: {
          text: "Für Freunde und Familie",
          tooltip: `
            Die öffentliche URL kannst du mit
            <br /> deinen Lieben teilen und ermöglicht
            <br /> diesen, deine Wünsche zu sehen und sie
            <br /> zu reservieren.
          `,
          copyDone: "Wunschzettel-Adresse wurde kopiert!",
        },
        forYou: {
          text: "Nur für dich",
          tooltip: `
            Behalte diese URL für dich!
            <br /> Mit ihr kannst du deinen Wunschzettel verwalten,
            <br /> neue Wünsche hinzufügen oder den Stil anpassen.
          `,
          copyDone: "Wunschzettel-Adresse wurde kopiert!",
        },
      },
      collaboration: {
        removeUser: "Nutzer entfernen",
        pending: "Nutzer wird entfernt",
        success: "Nutzer wurde entfernt",
        error: "Fehler aufgetreten",
      },
      affiliateHint: "Mit %{icon} gekennzeichnete Wünsche enthalten Affiliate-Werbelinks.",
    },
    giftFinderIndex: {
      teaser: `Du suchst eine Idee für deinen Wunschzettel oder den deiner Kinder? Lass 
        dich von unseren Tipps für Kinder, Jugendliche und Erwachsene inspirieren, damit
        es nicht wieder nur ein schnöder Gutschein wird. ;)
      `,
      cta: "Ideen ansehen",
    },
    passwordChange: {
      headline: "Passwort ändern",
      teaser1: "Du kannst dich nicht mehr an dein Passwort erinnern? Gar kein Problem!",
      teaser2:
        "Weiter unten kannst du dir für diese E-Mail-Adresse ein neues Passwort hinterlegen. Im Anschluss solltest du dich wieder ganz normalen Anmelden können. Los geht's!",
      cta: "Neues Passwort speichern",
    },
    sidebar: {
      newsletter: {
        title: "Neuigkeiten",
        text: "Abonniere unseren Newsletter und erfahre über neue Funktionen und Texte direkt nach dem Erscheinen!",
        emailPlaceholder: "Deine E-Mail-Adresse",
        submitButton: "Abonnieren",
      },
      blogPosts: {
        title: "Neuste Beiträge",
      },
      aboutUs: {
        title: "Über uns",
        text: `Wir, Sarah & Sascha, haben drei Kinder und bekommen so viele Fragen:
        Was wünschen sich die Kinder? Das steht nun auf dem eigenen Online-Wunschzettel! :)`,
      },
    },
    alerts: {
      loginSuccess: "Du hast dich erfolgreich eingeloggt. Viel Spaß mit deinen Wunschzetteln!",
      loginFailed:
        "Leider ist beim Einloggen ein Fehler aufgetreten. Vermutlich ist die Kombination aus E-Mail und Password ungültig.",
      logoutSuccess: "Du hast dich erfolgreich ausgeloggt!",
      registrationSuccess: "Dein Account wurde erfolgreich angelegt. Prüfe deine E-Mails für eine Aktivierungsmail.",
      passwordResetSuccess: "Bitte prüfe deine E-Mails für weitere Anweisungen!",
      passwordResetFailed:
        "Leider ist beim Zurücksetzen deines Passworts ein Fehler aufgetreten. Bitte überprüfe die eingegebene E-Mail-Adresse oder versuche es später erneut!",
      passwordUpdateSuccess: "Deine Passwort wurde erfolgreich geändert! Du kannst dich nun erneut anmelden.",
      thisWayToLogin: "Hier geht es zur Anmeldung",
      passwordUpdateFailed:
        "Leider ist beim Speichern deines neuen Passworts ein Fehler aufgetreten. Bitte überprüfe die eingegebenen Daten oder versuche es später erneut!",
      transientWishlist:
        "Du hast derzeit kein Nutzerkonto oder bist nicht eingeloggt. Bitte speichere die URL als Lesezeichen oder erstelle ein kostenloses Konto, damit du deine Wunschzettel nicht verbummelst.",
      transientWishlists:
        "Du bist nicht eingeloggt oder hast noch kein Konto. <br/> Lege dir jetzt ein <strong>kostenloses Konto</strong> an, damit deine Wünsche sicher sind.",
      activationSuccess: "Dein Account wurde erfolgreich aktiviert! Erstelle deinen ersten Wunschzettel noch heute :)",
      activationFailed:
        "Leider ist beim Aktivieren deines Kontos ein Fehler aufgetreten. Bitte versuche es später erneut!",
    },
    footer: {
      imprint: "Impressum",
      privacyPolicy: "Datenschutz",
      contact: "Kontakt",
      aboutUs: "Über uns",
    },
    passwordForgotten: {
      secondaryText: "Anschließend wirst du eine E-Mail erhalten, welche die weiteren Schritte erklärt.",
    },
    gdpr: {
      title: "Privatsphäre-Einstellungen",
      what: `Wir verwenden Cookies und ähnliche Technologien auf unserer
        Website und verarbeiten personenbezogene Daten (z.B. deine
        IP-Adresse) über dich. Wir teilen diese Daten auch mit
        Dritten. Die Datenverarbeitung kann mit deiner Einwilligung
        oder auf Basis eines berechtigten Interesses erfolgen, dem
        du in den individuellen Privatsphäre-Einstellungen
        widersprechen kannst. Du hast das Recht, nur in essenzielle
        Services einzuwilligen und deine Einwilligung in der
        Datenschutzerklärung zu einem späteren Zeitpunkt zu ändern
        oder zu widerrufen.
      `,
      under16: `Du bist unter 16 Jahre alt? Dann kannst du nicht in
        optionale Services einwilligen. Du kannst deine Eltern oder
        Erziehungsberechtigten bitten, mit dir in diese Services
        einzuwilligen.
      `,
      essentials: "Essenziell",
      functional: "Funktional",
      statistics: "Statistik",
      marketing: "Marketing",
      acceptAll: "Alle akzeptieren",
      continueWithoutConsent: "Weiter ohne Einwilligung",
      dataProtection: "Datenschutzerklärung",
      imprint: "Impressum",
    },
    accountIndex: {
      deleteAccount: {
        finePrint: `
          Du möchtest gern deinen Account löschen inklusive all deiner bisher erstellten Wünsche, Wunschzettel,
          Einladungen und möglicherweise hochgeladenen Bilder? Falls ja, dann benutze den Button unten, um den
          Prozess zu starten. Du erhälst im Anschluss eine E-Mail mit einem Link, den du bestätigen musst, um den
          Löschvorgang abzuschließen.
        `,
      },
    },
  },
};
