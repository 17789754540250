import { useI18n } from "../../hooks/use-i18n";
import { LanguagePicker } from "../LanguagePicker";
import SocialMedia from "../SocialMedia";
import "./style.less";

export default (props) => {
  const i18n = useI18n();

  return (
    <>
      <footer id="footer">
        <div className="container">
          <div id="footer-social">
            <SocialMedia withLabels={true} />
          </div>
          <LanguagePicker />
        </div>
      </footer>

      <div id="footer-bottom">
        <div className="container">
          <div className="copyright">
            <p>
              Copyright 2019-{new Date().getFullYear()} - bitte.kaufen - Mein
              Wunschzettel |&nbsp;
              <a href="/magazin/impressum/">{i18n.t("web.footer.imprint")}</a>
              &nbsp;|&nbsp;
              <a href="/magazin/datenschutz/">{i18n.t("web.footer.privacyPolicy")}</a>
              &nbsp;|&nbsp;
              <a href="/magazin/ueber-uns/">{i18n.t("web.footer.aboutUs")}</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
