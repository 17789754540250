import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18n } from "../../hooks/use-i18n";

export default (props) => {
  const i18n = useI18n();

  return (
    <div>
      <a href="https://facebook.com/bitte.kaufen" target="_blank">
        <FontAwesomeIcon icon={faFacebook} className="icon" />
        {props.withLabels && <span>Facebook</span>}
      </a>
      <a href="https://instagram.com/bitte.kaufen" target="_blank">
        <FontAwesomeIcon icon={faInstagram} className="icon" />
        {props.withLabels && <span>Instagram</span>}
      </a>
      <a href="https://www.pinterest.de/bittekaufen" target="_blank">
        <FontAwesomeIcon icon={faPinterest} className="icon" />
        {props.withLabels && <span>Pinterest</span>}
      </a>
      <a href="mailto:sarah@bitte.kaufen" target="_blank">
        <FontAwesomeIcon icon={faEnvelope} className="icon" />
        {props.withLabels && <span>{i18n.t("web.footer.contact")}</span>}
      </a>
    </div>
  );
};
