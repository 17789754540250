import en from "@bitte-kaufen/bitte-kaufen-common/dist/src/i18n/en.json";

export default {
  ...en,
  languages: {
    german: "German",
    english: "English",
  },
  web: {
    common: {
      createWishlist: "Create a wishlist",
      createAccount: "Create an account",
      toWishlist: "Visit wishlist",
      success: "Success!",
    },
    urls: {
      root: "/en",
      wishlistsIndex: "/en/wishlists",
      wishlistsShow: "/en/wishlists/%{uuid}",
      wishlistsShowPublic: "/en/wishlists/share/%{uuid}",
      friendsIndex: "/en/wishlists#friends",
      giftfinderIndex: "/en/giftfinder",
      giftfinderShow: "/en/giftfinder/%{uuid}",
      accountIndex: "/en/account",
      accountLogin: "/en/account/login",
      accountLogout: "/en/account/logout",
      accountRegister: "/en/account/register",
      passwordChange: "/en/account/password-change",
      passwordForgotten: "/en/account/password-forgotten",
    },
    login: {
      teaser:
        "Sign in to access your saved wishlists. In your account, you can save your wishes and send your wishlists to friends and family.",
    },
    register: {
      teaser1:
        "There are only benefits creating a shiny bitte.kaufen account. Promised! It will allow you comfortably manage all your wishlists in one central place, share them with friends, being invited as a collaborator to other pages and more! bitte.kaufen can be used free of charge - forever.",
      teaser2:
        "We try to store as little data as possible - both in cookies as well as on the server side. We are avid fans of data minimalism and hope you'll enjoy our wish platform. :)",
    },
    header: {
      ourApp: "Our App",
      account: "Account",
    },
    landingPage: {
      featuredArea: {
        title: "Create wishlists for free, share with friends & family and be surprised.",
      },
      benefits: {
        free: "Free to use",
        withoutRegistration: "No registration",
        easyToUse: "Intuitive & easy",
        ourApp: "Our app",
      },
      explanations: {
        nativeApp: {
          title: "Our wishlist app",
          body: [
            `Yay, our bitte.kaufen wishlist app is here! You can now download it for free!
               This is where you save your wishlists and those of your friends and family. You can also use it to invite others to collaborate.`,
          ],
        },
        free: {
          title: "Create wishlists for free",
          body: [
            `bitte.kaufen is and remains free for you and everyone, you want to share your wishes with. 
            We'll even give you something - besides to our online wishlist:
            Lots of gift ideas for children and adults.
            In case you don't know what to wish for. ;)`,
          ],
        },
        withoutRegistration: {
          title: "Without registration",
          body: [
            `You can simply fill in and send your wish list without registering. 
            However, we recommend creating a free account so that you don't have 
            to save all the links to your wish lists as bookmarks. We treat your 
            data confidentially.`,
          ],
        },
        forEveryone: {
          title: "Suitable for everyone",
          body: [
            `Every child can do that - and grandma and grandpa! We created bitte.kaufen 
            with the concept. Simply create a wish list, add wishes and look forward 
            to gifts. The wishlists are intended for everyone who is online but 
            sometimes wants to shop offline.`,
          ],
        },
        easyToUse: {
          title: "Intuitive & easy to use",
          body: [
            `Our goal: The online wish list should be easy to use. 
            Even children and grandparents should be able to simply create a 
            wish list and buy gifts for others. No desperate calls because everything 
            is complicated!`,
          ],
        },
        summary: {
          title: "Create a free wishlist",
          body: [
            `bitte.kaufen - your wishlist app is free - forever. Filling a wishlist 
            should make you happy and increase the anticipation of receiving presents. 
            That's why we promise you: You don't have to pay for our service, not even 
            with data.`,
            `Your data will be saved anonymously. Your unsubscribe from the outside 
            world cannot be found unless you share them with friends and family. 
            bitte.kaufen our online wish list also works without registration.
            You keep your data and thus your secrets to yourself. You only reveal 
            what you want from your loved ones. We keep our fingers crossed that 
            these wishes will come true. :)`,
          ],
        },
        forGrandParents: {
          title: "Simply understandable for grandparents",
          body: [
            `You are fed up that your wishlist is bound to a specific shopping platform 
            because grandma and grandpa have difficulties fulfilling the wishes? We feel you! 
            bitte.kaufen allows the creation of free wishlists, to which you can add all the 
            products of your favorite shops (including offline shops!).`,
            `Grandma and grandpa can now look for what is the easiest for them - e.g. in the shop 
            around the corner. With a single click, they reserve the desired wish for you and 
            are hence independent from online shops.`,
          ],
        },
        forEveryOccasion: {
          title: "Wishlists for every occasion",
          body: [
            `Find gifts with ease: Why don't you create a wishlist for your baby's birthday, 
            your children, for christmas, for easter or for your very wedding!? Your imagination 
            knows no boundaries. Hence it should be as simple as possible: Create a wishlist, share 
            them with friends, reserve a gift, as often as you want.`,
            `You are planning a secret bachelor farewell, grandma's 85th birthday or organize a 
            baby shower for a friend? You throw a barbecue party and everyone should bring something?
            You invite people to a board gaming evening or brunch with your favorite ones? No matter 
            what you use our online wishlist for, bitte.kaufen is easy to use and suitable for every occasion.`,
            `What's your next event?: Birthday, wedding, enrollment, christmas, easter or brunch? 
            With bitte.kaufen, you can build your own wishlist for every occasion. How about a wishlist 
            for the golden wedding of your grandparents you organize? Or you start with yourself and your most 
            secret wishes?`,
          ],
        },
      },
    },
    wishlistsIndex: {
      hero: {
        title: "Your wishlists",
        text: `This is an overview of all your wishlists. With just a few clicks, you can create a new wishlist
        and share it with your friends ... or just keep it safe and secret for yourself :)`,
      },
      separators: {
        yourWishlists: "Your wishlists",
        friendsWishlists: "Friends' wishlists",
      },
    },
    wishlistsCreate: {
      title: "New wishlist",
      titlePlaceholder: "Name of your new wishlist",
      submitButton: "Save wishlist",
    },
    wishlistsShow: {
      newWish: {
        title: "Add new wish",
        cta: "Save wish",
      },
      manage: {
        title: "Manage wishlist",
        invite: "Invite",
        move: "Move",
      },
      edit: {
        confirmWishDeletion: "Really delete wish!",
        title: "Title",
        hideBoughtWishesExplanation: `
          By enabling this option, bought wishes will be hidden from you. 
          This way you'll be more surprised by friends and family!
        `,
        image: "Image",
        scrollOnForMore: "Scroll on for more!",
      },
      share: {
        close: "Close sharing menu",
        forFriendsAndFamily: {
          text: "For friends and family",
          tooltip: `
            You can share this public URL 
            <br /> with your friends and family.
            <br /> They'll be able to see your wishes
            <br /> and can reserve and buy them.
          `,
          copyDone: "Wishlist address was copied!",
        },
        forYou: {
          text: "For you only",
          tooltip: `
            Please keep this URL secret.
            <br /> You can use it to manage your wishlist,
            <br /> add new wishes and change its style.
          `,
          copyDone: "Wishlist address was copied!",
        },
      },
      collaboration: {
        removeUser: "Remove user",
        pending: "User is being removed",
        success: "User was removed",
        error: "An error occurred",
      },
      affiliateHint: "Wishes that are marked with %{icon} contain affiliate weblinks.",
    },
    giftFinderIndex: {
      teaser: `You are looking for an idea for your or your children's wishlist?
        Find inspiration from our tips for children, adolescents and adults, so that
        it's not yet another boring voucher next time. ;)
      `,
      cta: "Find inspiration",
    },
    passwordChange: {
      headline: "Change Password",
      teaser1: "You can not remember your password anymore? No problem!",
      teaser2:
        "Please enter your new password into the following input field and submit the form. Afterwards you will be able to log in again. Let's do it!",
      cta: "Save the new password",
    },
    sidebar: {
      newsletter: {
        title: "News",
        text: "Subscribe to our newsletter and learn about new features and blog posts straight after the release!",
        emailPlaceholder: "Your email address",
        submitButton: "Subscribe now",
      },
      blogPosts: {
        title: "Latest blog posts",
      },
      aboutUs: {
        title: "About us",
        text: `We - Sarah & Sascha - have three children and have been asked so many times:
        Do the children have particular wishes? Well, each of them have their own online wishlist now! :)`,
      },
    },
    alerts: {
      loginSuccess: "You have successfully logged in. Have fun with your wishlists!",
      loginFailed:
        "Unfortunately, an error occurred while logging in. Presumably, the combination of e-mail and password is invalid.",
      logoutSuccess: "You have successfully logged out!",
      registrationSuccess:
        "Your account has been successfully created. Please check your inbox for an activation email.",
      passwordResetSuccess: "Please check your e-mails for further instructions!",
      passwordResetFailed:
        "Unfortunately, an error occurred while resetting your password. Please check the entered e-mail address or try again later!",
      passwordUpdateSuccess: "Your password has been successfully changed! You can now log in again.",
      thisWayToLogin: "This way for the login page!",
      passwordUpdateFailed:
        "Unfortunately, an error occurred while saving your new password. Please check the entered data or try again later!",
      transientWishlist:
        "You currently have no user account or are not logged in. Please bookmark this URL or create a free account, so that you do not lose your wishlists.",
      transientWishlists:
        "You currently have no user account or are not logged in. In oder to not lose your wishlists, we recommend the creation of a free user account.",
      activationSuccess: "Your account has been successfully activated! Create your first wishlist today :)",
      activationFailed: "Unfortunately, an error has occurred while enabling your account. Please try again later!",
    },
    footer: {
      imprint: "Imprint",
      privacyPolicy: "Privacy Policy",
      contact: "Contact",
      aboutUs: "About us",
    },
    passwordForgotten: {
      secondaryText: "Afterwards you will receive an e-mail that explains the further steps.",
    },
    gdpr: {
      title: "Privacy settings",
      what: `We use cookies and similar technologies on our website and process personal 
        data (e.g. your IP address) about you. We also share this data with third parties. 
        The data processing can take place with your consent or on the basis of a legitimate 
        interest, which you can object to in the individual privacy settings. You have the 
        right to only consent to essential services and to change or revoke your consent in 
        the data protection declaration at a later date.
      `,
      under16: `Are you under 16 years old? Then you cannot consent to optional services. 
        You can ask your parent or legal guardian to agree to these services with you.
      `,
      essentials: "Essentials",
      functional: "Functional",
      statistics: "Statistics",
      marketing: "Marketing",
      acceptAll: "Accept all",
      continueWithoutConsent: "Continue without consent",
      dataProtection: "Data protection declaration",
      imprint: "Imprint",
    },
    accountIndex: {
      deleteAccount: {
        finePrint: `
          You would like to delete your account, including all your created wishes, wishlists, 
          invitations, and possibly uploaded pictures? If so, please use the button below to 
          initiate the process. Subsequently, you will receive an email with a link that you 
          must confirm to complete the deletion process.
        `,
      },
    },
  },
};
