export const ESSENTIALS = {
  name: "ESSENTIALS",
  groupId: 14324,
  cookieIds: [23896, 23881, 23879],
};

export const FUNCTIONAL = {
  name: "FUNCTIONAL",
  groupId: 14325,
  cookieIds: [23906, 23904, 23902, 23900, 23898, 23897, 23894, 23889, 23887],
};

export const STATISTICS = {
  name: "STATISTICS",
  groupId: 14326,
  cookieIds: [23984, 23885],
};

export const MARKETING = {
  name: "MARKETING",
  groupId: 14327,
  cookieIds: [23893, 23891, 23883, 23880],
};

export const COOKIE_NAME_BLOG = "real_cookie_banner-blog:1";
export const COOKIE_NAME = "bitte-kaufen-gdpr-consent";
