import "./style.less";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isoCookie from "isomorphic-cookie";
import classNames from "classnames";
import { getExpires } from "../../models/cookie";

export default class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.cookieKey = `alert-${this.props.id}`;
    this.state = {
      seen: true,
    };
  }

  hide(e) {
    e.preventDefault();
    isoCookie.save(this.cookieKey, "seen", {
      secure: process.env.NODE_ENV === "production",
      expires: getExpires(),
    });
    this.setState({ seen: true });
  }

  componentDidMount() {
    this.setState({ seen: !!isoCookie.load(this.cookieKey) });
  }

  render() {
    return (
      this.state.seen || (
        <div className="sp-container">
          <div className={classNames("alert-message", this.props.className)}>
            <div className="alert-content">
              {this.props.icon && (
                <FontAwesomeIcon
                  icon={this.props.icon}
                  className="icon"
                  size="lg"
                />
              )}
              {this.props.children}
            </div>

            <div className="link-list">
              {this.props.additionalLinks}

              {!this.props.withoutIgnore && (
                <div className="close-link">
                  <button className="btn" onClick={(e) => this.hide(e)}>
                    Hinweis ignorieren
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}
