import Link from "next/link";
import { withRouter } from "next/router";
import React, { useEffect } from "react";
import { useI18n } from "../../hooks/use-i18n";
import "./style.less";

function CookieNotice({ router }) {
  const i18n = useI18n();

  useEffect(() => {
    const wrapper = document.querySelector("#wrapper");

    if (wrapper &&!wrapper.classList.contains("blurred")) {
      wrapper.classList.add("blurred");
    }
  }, []);

  function getGdprLink(agreeToAllCookies) {
    const groups = agreeToAllCookies
      ? ["ESSENTIALS", "FUNCTIONAL", "STATISTICS", "MARKETING"]
      : ["ESSENTIALS"];
    const ru = encodeURIComponent(router.asPath);

    return `/gdpr?groups=${groups.join(",")}&ru=${ru}`;
  }

  return (
    <>
      <div className="cookie-notice-overlay"></div>
      <div className="cookie-notice__wrapper">
        <div className="cookie-notice__inner-wrapper">
          <div className="cookie-notice__header-wrapper">
            <div className="cookie-notice__header-inner-wrapper">
              <img src="https://bitte.kaufen/blog/wp-content/uploads/2019/12/bitte-kaufen-logo-startseite-schrift.png" />
              <div className="cookie-notice__header-text">
                {i18n.t("web.gdpr.title")}
              </div>
            </div>
          </div>
          <div className="cookie-notice__divider"></div>
          <div className="cookie-notice__content-wrapper">
            <div className="cookie-notice__content-inner-wrapper">
              <div className="cookie-notice__content-left">
                <div className="cookie-notice__content-left-primary-text">
                  <span>{i18n.t("web.gdpr.what")}</span>
                  <div>
                    <span className="cookie-notice__content-left-divider"></span>
                  </div>
                  <span className="cookie-notice__content-left-secondary-text">
                    {i18n.t("web.gdpr.under16")}
                  </span>
                </div>
                <div className="cookie-notice__content-left-classification">
                  <span className="cookie-notice__content-left-classification-wrapper">
                    <span>
                      <strong>●</strong>&nbsp;
                      <span>{i18n.t("web.gdpr.essentials")}</span>
                    </span>
                  </span>
                  <span className="cookie-notice__content-left-classification-wrapper">
                    <span>
                      <strong>●</strong>&nbsp;
                      <span>{i18n.t("web.gdpr.functional")}</span>
                    </span>
                  </span>
                  <span className="cookie-notice__content-left-classification-wrapper">
                    <span>
                      <strong>●</strong>&nbsp;
                      <span>{i18n.t("web.gdpr.statistics")}</span>
                    </span>
                  </span>
                  <span className="cookie-notice__content-left-classification-wrapper">
                    <span>
                      <strong>●</strong>&nbsp;
                      <span>{i18n.t("web.gdpr.marketing")}</span>
                    </span>
                  </span>
                </div>
              </div>
              <div className="cookie-notice__content-right">
                <Link href={getGdprLink(true)}>
                  <a className="cookie-notice__content-right-button primary">
                    <span>{i18n.t("web.gdpr.acceptAll")}</span>
                  </a>
                </Link>
                <Link href={getGdprLink(false)}>
                  <a className="cookie-notice__content-right-button secondary">
                    <span>{i18n.t("web.gdpr.continueWithoutConsent")}</span>
                  </a>
                </Link>
              </div>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
          <div className="cookie-notice__bottom-divider"></div>
          <div className="cookie-notice__bottom-links-wrapper">
            <div>
              <Link href="https://bitte.kaufen/magazin/datenschutz/">
                <a target="_blank" rel="noopener">
                  {i18n.t("web.gdpr.dataProtection")}
                </a>
              </Link>
              •
              <Link href="https://bitte.kaufen/magazin/impressum/">
                <a target="_blank" rel="noopener">
                  {i18n.t("web.gdpr.imprint")}
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(CookieNotice);
