export const DEFAULT_IMAGES = [
  "https://bitte.kaufen/images/wishlist-backgrounds/geschenk.png",
  "https://bitte.kaufen/images/wishlist-backgrounds/geschenk-mit-glitter.jpg",
  "https://bitte.kaufen/images/wishlist-backgrounds/herzen.jpg",
];
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getMetaInformation } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/meta";
import { useEffect, useState } from "react";
import "./style.less";

export default function WishlistImagePicker({ i18n, image, onChange }) {
  const [defaultImages, setDefaultImages] = useState(undefined);
  const [selectedImage, setSelectedImage] = useState(image);
  const onSelect = (image) => {
    setSelectedImage(image);
    onChange({ image });
  };

  useEffect(() => {
    getMetaInformation().then((metaInfo) => {
      setDefaultImages(metaInfo.wishlistImages);
    });
  }, []);

  if (!defaultImages) {
    return (
      <div className="wishlist-image-picker">
        <FontAwesomeIcon icon={faSpinner} className="icon" spin />
      </div>
    );
  }

  return (
    <div>
      <div className="wishlist-image-picker">
        {defaultImages.map((image, i) => (
          <div
            key={i}
            className={`wishlist-background ${
              image === selectedImage ? "selected" : ""
            }`}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => onSelect(image)}
          ></div>
        ))}
        <input type="text" name="image" value={selectedImage} />
      </div>
      <div className="scroll-for-more-overlay">
        <div>{i18n.t('web.wishlistsShow.edit.scrollOnForMore')}</div>
      </div>
    </div>
  );
}
