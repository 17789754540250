import compactLogo from "./logo-online-wunschzettel-bitte-kaufen-plain.png";
import smallLogo from "./logo-online-wunschzettel-bitte-kaufen.png";
import "./style.less";
import Link from "next/link";
import classNames from "classnames";
import { useI18n } from "../../hooks/use-i18n";

export default (props) => {
  const i18n = useI18n();
  const url = props.compact ? compactLogo : smallLogo;
  const content = (
    <Link href={i18n.t("web.urls.root")}>
      <a>
        <img
          className={classNames("logo", { compact: props.compact })}
          src={url}
          alt="Logo Online Wunschzettel bitte.kaufen"
        />
      </a>
    </Link>
  );

  return props.mobile ? <div id="mobile-logo">{content}</div> : content;
};
