import Head from "next/head";
import { withRouter } from "next/router";
import { useEffect } from "react";
import AlertFactory from "../../components/Alert/factory";
import { getWebServerAddress } from "../../helpers/url";
import { useI18n } from "../../hooks/use-i18n";
import Footer from "../Footer";
import Header from "../Header";
import Logo from "../Logo";
import { CookieHook } from "./CookieHook";
import "./style-01-reset.less";
import "./style-misc.less";
import "./style-post.less";
import "./style-responsive.less";
import "./style.less";

const DEFAULT_TITLE = "bitte.kaufen ♥ Wunschzettel kostenlos anlegen";
const DEFAULT_DESCRIPTION =
  "Leg deine Wunschliste auf bitte.kaufen an und teile sie! ✔ Kostenlos ✔ Ohne Anmeldung ✔ Für jeden geeignet ✔ Einfach";

function Layout({
  router,
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  className,
  user,
  hideMobileLogo,
  preGridContent,
  children,
  noCookies,
  noIndex,
}) {
  const i18n = useI18n();
  const isProd = process.env.NODE_ENV === "production";
  const alertBlacklist = ["activation-failed", "logout-success"];
  const renderAlert =
    router.query.alert && !alertBlacklist.includes(router.query.alert);
  const canonicalUrl = `${getWebServerAddress()}${router.asPath.replace(
    /\/$/,
    ""
  )}`;

  useEffect(() => {
    if (renderAlert) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <Head>
        {(!isProd || noIndex) && <meta name="robots" content="noindex" />}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="icon" href="/images/favicon.png" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="p:domain_verify"
          content="b325a8c5f2bc7cddb087da06e5e9a787"
        />
        <meta
          name="facebook-domain-verification"
          content="vw0gwq323lym3n6bfodbpshwk7jxg0"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap"
          rel="stylesheet"
        />
      </Head>
      <div id="wrapper" className={className}>
        <Header user={user} />
        <div id="inner-wrapper">
          <div className="mobile-logo-wrapper">
            {!hideMobileLogo && <Logo mobile />}
          </div>
          {renderAlert && AlertFactory(router.query.alert, i18n)}
          {preGridContent && preGridContent()}
          <div className="sp-container">
            <div className="sp-row">{children}</div>
          </div>
          <Footer />
        </div>
      </div>

      {!noCookies && <CookieHook />}

      <script type="text/javascript" src="/javascript/jquery-bundle.js" />
      <script src="https://cdn.jsdelivr.net/npm/party-js@latest/bundle/party.min.js"></script>
    </>
  );
}

export default withRouter(Layout);
