import isoCookie from "isomorphic-cookie";

export function getExpires() {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 10));
}

export const I18N_COOKIE_KEY = "preferred_language";

export function hasI18nCookie(req) {
  return !!getI18nCookie(req);
}

export function getI18nCookie(req) {
  return isoCookie.load(I18N_COOKIE_KEY, req);
}

export function setI18nCookie(res, value) {
  return isoCookie.save(
    I18N_COOKIE_KEY,
    value,
    {
      secure: process.env.NODE_ENV === "production",
      expires: getExpires(),
      path: "/",
    },
    res
  );
}
