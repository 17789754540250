import Head from "next/head";
import { useEffect } from "react";
import { MARKETING } from "../../config/gdpr";
import { getCookieNoticeAcceptanceState, hasAgreedToCookieUsageType } from "../../models/cookie-notice";
import CookieNotice from "../CookieNotice";

export function CookieHook({ returnUrl }) {
  const [loaded, setLoaded] = React.useState(false);
  const [acceptanceState, setAcceptanceState] = React.useState(undefined);

  const acceptedMarketingCookies = hasAgreedToCookieUsageType(MARKETING);
  const isProd = process.env.NODE_ENV === "production";

  useEffect(() => {
    setLoaded(true);
    setAcceptanceState(getCookieNoticeAcceptanceState());
  }, []);

  return (
    <>
      {isProd && acceptedMarketingCookies && (
        <Head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-ZMECFR5KHD" />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-ZMECFR5KHD');
              `,
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '3912317602325943'); fbq('track', 'PageView');`,
            }}
          />
          <noscript>
            <img
              height={1}
              width={1}
              style={{ display: "none" }}
              src="https://www.facebook.com/tr?id=3912317602325943&ev=PageView&noscript=1"
            />
          </noscript>

          <script
            dangerouslySetInnerHTML={{
              __html: `
                !function(){"use strict";!function(e,t){var n=e.amplitude||{_q:[],_iq:{}};if(n.invoked)e.console&&console.error&&console.error("Amplitude snippet has been loaded.");else{var r=function(e,t){e.prototype[t]=function(){return this._q.push({name:t,args:Array.prototype.slice.call(arguments,0)}),this}},s=function(e,t,n){return function(r){e._q.push({name:t,args:Array.prototype.slice.call(n,0),resolve:r})}},o=function(e,t,n){e[t]=function(){if(n)return{promise:new Promise(s(e,t,Array.prototype.slice.call(arguments)))}}},i=function(e){for(var t=0;t<m.length;t++)o(e,m[t],!1);for(var n=0;n<g.length;n++)o(e,g[n],!0)};n.invoked=!0;var u=t.createElement("script");u.type="text/javascript",u.integrity="sha384-x0ik2D45ZDEEEpYpEuDpmj05fY91P7EOZkgdKmq4dKL/ZAVcufJ+nULFtGn0HIZE",u.crossOrigin="anonymous",u.async=!0,u.src="https://cdn.amplitude.com/libs/analytics-browser-2.0.0-min.js.gz",u.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var a=t.getElementsByTagName("script")[0];a.parentNode.insertBefore(u,a);for(var c=function(){return this._q=[],this},p=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove","getUserProperties"],l=0;l<p.length;l++)r(c,p[l]);n.Identify=c;for(var d=function(){return this._q=[],this},f=["getEventProperties","setProductId","setQuantity","setPrice","setRevenue","setRevenueType","setEventProperties"],v=0;v<f.length;v++)r(d,f[v]);n.Revenue=d;var m=["getDeviceId","setDeviceId","getSessionId","setSessionId","getUserId","setUserId","setOptOut","setTransport","reset","extendSession"],g=["init","add","remove","track","logEvent","identify","groupIdentify","setGroup","revenue","flush"];i(n),n.createInstance=function(e){return n._iq[e]={_q:[]},i(n._iq[e]),n._iq[e]},e.amplitude=n}}(window,document)}();

                if(amplitude) {
                  amplitude.init('2a47bba3a22bde364b244968d3001d36');
                
                  document.addEventListener("click", (e) => {
                    if (e.target) {
                      try {
                        amplitude.track("[Amplitude] Element Clicked", {
                          "[Amplitude] Page Title": document.title,
                          "[Amplitude] Element Class": Array.from(e.target.classList).join(","),
                          "[Amplitude] Element Href": e.target.href,
                          "[Amplitude] Element ID": e.target.id,
                          "[Amplitude] Element Text": e.target.innerText,
                          "[Amplitude] Element Tag": e.target.tagName,
                          "[Amplitude] Page URL": document.location.href,
                        });
                      } catch (e) {
                        /* noop */
                      }
                    }
                  });
                
                  window.addEventListener("beforeunload", () => {
                    amplitude.flush();
                  });
                }
              `,
            }}
          />
        </Head>
      )}
      {loaded && acceptanceState === undefined && <CookieNotice />}
    </>
  );
}
