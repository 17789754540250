import { faCheckCircle, faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import {
  faExclamationCircle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import Alert from "./";

export default function (identifier, i18n, options = {}) {
  switch (identifier) {
    case "login-success":
      return (
        <Alert
          icon={faCheckCircle}
          className={`success ${identifier}`}
          withoutIgnore
        >
          {i18n.t("web.alerts.loginSuccess")}
        </Alert>
      );
    case "login-failed":
      return (
        <Alert
          icon={faExclamationCircle}
          className={`error ${identifier}`}
          withoutIgnore
        >
          {i18n.t("web.alerts.loginFailed")}
        </Alert>
      );
    case "logout-success":
      return (
        <Alert
          icon={faCheckCircle}
          className={`success ${identifier}`}
          withoutIgnore
        >
          {i18n.t("web.alerts.logoutSuccess")}
        </Alert>
      );
    case "registration-success":
      return (
        <Alert
          icon={faThumbsUp}
          className={`success ${identifier}`}
          withoutIgnore
        >
          <h2>{i18n.t("web.common.success")}</h2>
          <p>{i18n.t("web.alerts.registrationSuccess")}</p>
        </Alert>
      );
    case "password-reset-success":
      return (
        <Alert
          icon={faThumbsUp}
          className={`success ${identifier}`}
          withoutIgnore
        >
          <h2>{i18n.t("web.common.success")}</h2>
          <p>{i18n.t("web.alerts.passwordResetSuccess")}</p>
        </Alert>
      );
    case "password-reset-failed":
      return (
        <Alert
          icon={faExclamationCircle}
          className={`error ${identifier}`}
          withoutIgnore
        >
          {i18n.t("web.alerts.passwordResetFailed")}
        </Alert>
      );
    case "password-update-success":
      return (
        <Alert
          icon={faThumbsUp}
          className={`success ${identifier}`}
          withoutIgnore
        >
          <h2>{i18n.t("web.common.success")}</h2>
          <p>{i18n.t("web.alerts.passwordUpdateSuccess")}</p>
          <p>
            <Link href={i18n.t("web.urls.accountLogin")}>
              <a>{i18n.t("web.alerts.thisWayToLogin")}</a>
            </Link>
          </p>
        </Alert>
      );
    case "password-update-failed":
      return (
        <Alert
          icon={faExclamationCircle}
          className={`error ${identifier}`}
          withoutIgnore
        >
          {i18n.t("web.alerts.passwordUpdateFailed")}
        </Alert>
      );
    case "transient-wishlist":
      return (
        <Alert icon={faInfoCircle} id="transient-wishlist" {...options}>
          {i18n.t("web.alerts.transientWishlist")}
        </Alert>
      );
    case "transient-wishlists":
      return (
        <Alert icon={faInfoCircle} id="transient-wishlist" {...options}>
          <div
            dangerouslySetInnerHTML={{
              __html: i18n.t("web.alerts.transientWishlists"),
            }}
          />
        </Alert>
      );
    case "activation-success":
      return (
        <Alert
          icon={faCheckCircle}
          className={`success ${identifier}`}
          withoutIgnore
        >
          <p>{i18n.t("web.alerts.activationSuccess")}</p>
        </Alert>
      );
    case "activation-failed":
      return (
        <Alert
          icon={faExclamationCircle}
          className={`error ${identifier}`}
          withoutIgnore
        >
          {i18n.t("web.alerts.activationFailed")}
        </Alert>
      );
  }
}
