import { faChevronDown, faUser, faUserFriends, faUserTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGiftFinderCategories } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/gift-finder";
import { toSlug } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/string";
import classNames from "classnames";
import Link from "next/link";
import { withRouter } from "next/router";
import PubSub from "pubsub-js";
import { getBookmarks } from "../../models/bookmark";
import i18n from "../../models/i18n";
import { getUserInfo } from "../../models/user";
import { getWishlists, readFromCookies } from "../../models/wishlist";
import Logo from "../Logo";
import SocialMedia from "../SocialMedia";
import "./style.less";

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      wishlists: [],
      bookmarks: [],
      giftFinder: [],
      initSlickNav: false,
    };

    PubSub.subscribe("header.refresh", () => {
      this.updateData();
    });
  }

  get menuItems() {
    const personalWishlistItems = this.state.wishlists.map((wishlist) => {
      return {
        text: (
          <span>
            <FontAwesomeIcon icon={wishlist.isCollaboration ? faUserFriends : faUser} className="icon" />
            &nbsp;&nbsp;
            {wishlist.title}
          </span>
        ),
        to: i18n.t("web.urls.wishlistsShow", { uuid: wishlist.uuid }),
      };
    });
    const bookmarkItems = this.state.bookmarks.map((bookmark) => {
      const url = i18n.t("web.urls.wishlistsShowPublic", {
        uuid: bookmark.wishlist.publicUuid,
      });

      return {
        text: (
          <span>
            <FontAwesomeIcon icon={faUserTag} className="icon" />
            &nbsp;&nbsp;
            {bookmark.wishlist.title}
          </span>
        ),
        to: url,
      };
    });
    const giftFinderItems = this.state.giftFinder.map((wishlist) => ({
      text: <span>{wishlist.title}</span>,
      to: i18n.t("web.urls.giftfinderShow", { uuid: toSlug(wishlist.title) }),
    }));

    const items = [
      {
        text: "Magazin",
        to: "/magazin/",
        external: true,
      },
      {
        text: i18n.t("myWishlists.headerTitle"),
        to: i18n.t("web.urls.wishlistsIndex"),
        items: personalWishlistItems.length ? personalWishlistItems : undefined,
      },
      this.state.user && {
        text: i18n.t("friends.tabLabel"),
        to: i18n.t("web.urls.friendsIndex"),
        items: bookmarkItems.length ? bookmarkItems : undefined,
      },
      i18n.currentLocale() === "de" && {
        text: i18n.t("giftFinder.tabLabel"),
        to: i18n.t("web.urls.giftfinderIndex"),
        items: giftFinderItems.length ? giftFinderItems : undefined,
      },
      {
        text: i18n.t("web.header.ourApp"),
        to: "/magazin/wunschzettel-app-ios-android/",
      },
    ].filter((item) => !!item);
    const guestItems = [
      {
        text: i18n.t("web.header.account"),
        to: i18n.t("web.urls.accountLogin"),
        items: [
          {
            text: i18n.t("login.tabLabel"),
            to: i18n.t("web.urls.accountLogin"),
          },
          {
            text: i18n.t("register.tabLabel"),
            to: i18n.t("web.urls.accountRegister"),
          },
          {
            text: i18n.t("login.form.passwordForgotten"),
            to: i18n.t("web.urls.passwordForgotten"),
          },
        ],
      },
    ];
    const firstOrUsername = this.state.user && (this.state.user.firstName || this.state.user.username);
    const memberItems = [
      {
        text: `${i18n.t("web.header.account")} (${firstOrUsername})`,
        to: i18n.t("web.urls.accountIndex"),
        items: [
          {
            text: i18n.t("login.form.passwordForgotten"),
            to: i18n.t("web.urls.passwordForgotten"),
          },
          {
            text: i18n.t("profile.logout.logout"),
            to: i18n.t("web.urls.accountLogout"),
          },
        ],
      },
    ];

    return items.concat(this.state.user ? memberItems : guestItems);
  }

  async updateData(callback) {
    const user = await getUserInfo();
    const dataRequests = [getBookmarks(), readFromCookies(undefined, { enrich: !user }), getGiftFinderCategories()];

    if (user) {
      dataRequests.push(getWishlists());
    }

    const [bookmarks, transientWishlists, giftFinder, permanentWishlists] = await Promise.all(dataRequests);

    this.setState(
      {
        wishlists: (permanentWishlists || []).concat(transientWishlists),
        bookmarks,
        giftFinder,
        user,
      },
      callback
    );
  }

  async componentDidMount() {
    // Sticky Menu
    var stickyNavTop = jQuery("#top-bar").offset().top;

    var stickyNav = function () {
      var scrollTop = jQuery(window).scrollTop();

      if (scrollTop > 0) {
        jQuery("#top-bar").addClass("sticky");
      } else {
        jQuery("#top-bar").removeClass("sticky");
      }
    };

    stickyNav();

    jQuery(window).scroll(function () {
      stickyNav();
    });

    this.updateData(() => {
      jQuery("#nav-wrapper .menu").slicknav({
        prependTo: "#slick-mobile-menu",
        label: "Menü",
        allowParentLinks: true,
      });
    });
  }

  render() {
    return (
      <header id="top-bar">
        <div id="logo">
          <Logo />
        </div>

        <nav id="navigation">
          <div id="nav-wrapper">
            <ul className="menu">
              {this.menuItems.map(({ text, to, external, className, items }, i) => (
                <li
                  key={i}
                  className={classNames("menu-item menu-item-type-taxonomy menu-item-object-category", {
                    "menu-item-has-children": !!items,
                  })}
                >
                  {external && (
                    <>
                      <a href={to} className={classNames({ selected: to === this.props.router.asPath })}>
                        <span className="menu-item-label">{text}</span>
                        {items && <FontAwesomeIcon icon={faChevronDown} className="icon" />}
                      </a>
                      {items && (
                        <ul className="sub-menu">
                          {items.map((item, i) => (
                            <li key={i} className="menu-item menu-item-type-post_type menu-item-object-page">
                              <a href={item.to}>{item.text}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  )}
                  {!external && (
                    <>
                      <Link href={to}>
                        <a className={classNames(className, { selected: to === this.props.router.asPath })}>
                          <span className="menu-item-label">{text}</span>
                          {items && <FontAwesomeIcon icon={faChevronDown} className="icon" />}{" "}
                        </a>
                      </Link>
                      {items && (
                        <ul className="sub-menu">
                          {items.map((item, i) => (
                            <li key={i} className="menu-item menu-item-type-post_type menu-item-object-page">
                              <a href={item.to}>{item.text}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div id="slick-mobile-menu"></div>
        </nav>
        <div id="top-middle" className="smartphone-only">
          <div id="header-title">
            <Link href={i18n.t("web.urls.root")}>
              <a>bitte.kaufen</a>
            </Link>
          </div>
        </div>
        <div id="top-right">
          <div id="top-social" className="desktop-only">
            <SocialMedia />
          </div>
          <div id="logo-mobile" className="smartphone-only">
            <Logo compact />
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
