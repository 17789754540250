import i18n from "i18n-js";
import { translations } from "../translations";

export default {
  source: i18n,

  setLocale(locale) {
    i18n.defaultLocale = "de";
    i18n.fallbacks = true;
    i18n.translations = translations;
    i18n.locale = locale;
  },

  t(key, options) {
    return i18n.t(key, options);
  },

  currentLocale() {
    return i18n.currentLocale();
  },
};
