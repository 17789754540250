import fetch from "isomorphic-unfetch";
import endpointConfig from "../config/endpoints";
import { sanitizeWishlist } from "@bitte-kaufen/bitte-kaufen-common/dist/src/models/wishlist";

export async function isBookmarked(cookie, publicUuid) {
  const bookmarks = await getBookmarks(cookie);

  return bookmarks.some(
    (bookmark) => bookmark.wishlist.publicUuid === publicUuid
  );
}

export async function getBookmarks(cookie) {
  const options = cookie
    ? {
        headers: {
          Cookie: cookie,
        },
      }
    : {};

  return fetch(endpointConfig().bookmarks, {
    credentials: "include",
    ...options,
  })
    .then((res) => res.ok && res.json())
    .then((bookmarks) => bookmarks.map(sanitizeBookmark));
}

export function createBookmark(wishlist) {
  const config = endpointConfig();

  return fetch(config.bookmarks, {
    method: "POST",
    body: JSON.stringify({
      wishlist: {
        publicUuid: wishlist.publicUuid,
      },
    }),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.ok && response.json())
    .then(sanitizeBookmark);
}

export function destroyBookmark(wishlist) {
  const config = endpointConfig();

  return fetch(config.bookmark(wishlist.publicUuid), {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function sanitizeBookmark(bookmark) {
  return {
    ...bookmark,
    wishlist: sanitizeWishlist(bookmark.wishlist),
  };
}
